import api from 'services/api';
import { IAdminData } from '../interfaces';
import {
  IConfirmTransferFunds,
  IDismissPromotionModalPayload,
  IExecuteNodeDeletion,
  INotificationWarningType,
} from './actions';

export interface INotificationWarningData {
  type: INotificationWarningType;
  isEnabled: boolean;
}

export interface IDifferentAccountData {
  username: string;
  email: string;
}

interface IRestoreNodeRequest {
  deletedNodeId: number;
  shouldRestore: boolean;
}

interface IChangeEmailBody {
  newEmail: string;
  confirmNewEmail: string;
  code: string;
}

export const updateProfileRequest = async (profile: any): Promise<any> => {
  try {
    const { data } = await api.put('/users', profile);

    return data;
  } catch (err) {
    console.error('Something went wrong on updateProfileRequest.');
    throw err;
  }
};

export const updateMasternodesToStartNotificationRequest = async (dataBody: any): Promise<any> => {
  try {
    const { data } = await api.put('/users', dataBody);

    return data;
  } catch (err) {
    console.error('Something went wrong on updateProfileRequest.');
    throw err;
  }
};

export const sendPromotionCodeRequest = async (promotionCode: string) => {
  try {
    await api.put(`promotion_code?code=${promotionCode}`);
  } catch (err) {
    console.error('Something went wrong on sendPromotionCodeRequest.');
    throw err;
  }
};

export const resetIndexToDeleteRequest = async (): Promise<{ allNodes: number; resetCount: number }> => {
  try {
    const { data } = await api.put(`nodes/reset_index`);
    const { allNodes, resetCount } = data;
    return {
      allNodes,
      resetCount,
    };
  } catch (err) {
    console.error('Something went wrong on resetIndexToDeleteRequest.');
    throw err;
  }
};

export const getAdminDataRequest = async (): Promise<IAdminData> => {
  try {
    const { data: adminData } = await api.get(`admin`);
    return adminData;
  } catch (err) {
    console.error('Something went wrong on getAdminDataRequest.');
    throw err;
  }
};

export const toggleNotificationWarningRequest = async (warningMailType: string): Promise<INotificationWarningData> => {
  try {
    const { data: notificationWarningData } = await api.put(`toggle_mail_warning/${warningMailType}`);
    return notificationWarningData;
  } catch (err) {
    console.error('Something went wrong on toggleNotificationWarningRequest.');
    throw err;
  }
};

export const submitAdminCodeRequest = async (code: string): Promise<boolean> => {
  try {
    api.defaults.headers.c = code;
    await api.post(`admin/code`);
    return true;
  } catch (err) {
    throw err;
  }
};

export const restoreNodeRequest = async ({ deletedNodeId, shouldRestore }: IRestoreNodeRequest): Promise<any> => {
  try {
    await api.put(`restore_node/${deletedNodeId}`, { shouldRestore });
  } catch (err) {
    throw err;
  }
};

export const verifyDifferentAccountEmailRequest = async (
  differentAccountEmail: string
): Promise<IDifferentAccountData> => {
  const params = {
    differentAccountEmail,
  };
  try {
    const { data } = await api.get(`transfer_funds/verify_different_account`, {
      params,
    });
    return data;
  } catch (err) {
    throw err;
  }
};

export const confirmTransferFundsRequest = async ({
  balance,
  bonus,
  code,
  differentAccountEmail,
}: IConfirmTransferFunds): Promise<any> => {
  const body = {
    balance,
    bonus,
    code,
    differentAccountEmail,
  };
  try {
    await api.post(`transfer_funds`, body);
  } catch (err) {
    throw err;
  }
};

export const deleteDecommissionDataRequest = async (): Promise<any> => {
  try {
    await api.delete(`admin/decommission`);
  } catch (err) {
    throw err;
  }
};

export const deleteDecommissionItemRequest = async (decommissionItemId: number): Promise<any> => {
  try {
    await api.delete(`admin/decommission/${decommissionItemId}`);
  } catch (err) {
    throw err;
  }
};

export const updateServerMounting = async (node_base: string, should_mount: boolean) => {
  try {
    await api.put(`servers/mounting`, {
      node_base,
      should_mount,
    });
  } catch (err) {
    throw err;
  }
};

export const updateServerDeletion = async (node_base: string, should_delete: boolean) => {
  try {
    await api.put(`servers/deletion`, {
      node_base,
      should_delete,
    });
  } catch (err) {
    throw err;
  }
};

export const chargeNodes = async () => {
  try {
    const { data } = await api.put(`charge_nodes`);
    return data.totalCharges;
  } catch (err) {
    throw err;
  }
};

export const deleteNodesRequest = async ({ shouldDeleteOnlyReadyNodes }: IExecuteNodeDeletion) => {
  try {
    const { data } = await api.delete(`node_deletion`, {
      data: {
        shouldDeleteOnlyReadyNodes,
      },
    });
    return data.totalDeletedNodes;
  } catch (err) {
    throw err;
  }
};

export const dismissPromotionModalRequest = async ({
  shouldSeePromotionModal,
  promotionPriceCode,
}: IDismissPromotionModalPayload) => {
  try {
    await api.put(`promotion_modal`, {
      shouldSeePromotionModal,
      promotionPriceCode,
    });
  } catch (err) {
    throw err;
  }
};

export const changeEmailRequest = async (requestBody: IChangeEmailBody) => {
  try {
    const { confirmNewEmail, newEmail, code } = requestBody;
    const { data } = await api.put(`change_email`, {
      newEmailConfirmation: confirmNewEmail,
      newEmail,
      code,
    });
    return data;
  } catch (err) {
    throw err;
  }
};

export const createOnchainPaymentLinkRequest = async () => {
  try {
    const { data } = await api.post(`addfunds/onchain`);
    return data;
  } catch (err) {
    throw err;
  }
};
