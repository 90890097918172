import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { PiConfettiFill } from 'react-icons/pi';

import AuthLayout from 'pages/_layouts/auth';
import DefaultLayout from 'pages/_layouts/default';
import { getAuth } from 'store/modules/auth/selectors';
import { isLoadingAppData as isLoadingAppDataSelector } from '~/store/modules/app/selectors';
import {
  ItemContainer,
  ModalFooterContainer,
  MoreInfoButton,
  PromotionIcon,
  PromotionModalBodyContainer,
  StyledModal,
  TextRenderLine,
} from './styles';
import { TextRender } from '~/components/ui/TextRender';
import history from '~/services/history';
import { Checkbox } from '~/components/ui/Checkbox';
import {
  getAnyPromotionActiveForNodes,
  getShouldShowPromotionModalForNotSignedUser,
  getShouldShowPromotionModalForSignedUser,
} from '~/store/modules/user/selectors';
import { dismissPromotionModal } from '~/store/modules/user/actions';

interface IRoute {
  exact?: boolean;
  component: any;
  isPrivate?: boolean;
  isGeneric?: boolean;
  path?: string;
}

const PROMOTION_ICON_SIZE = 30;

export default function RouteWrapper({ component: Component, isPrivate = false, isGeneric, ...rest }: IRoute) {
  const shouldShowPromotionModalForSignedUser = useSelector(getShouldShowPromotionModalForSignedUser);
  const shouldShowPromotionModalForNotSignedUser = useSelector(getShouldShowPromotionModalForNotSignedUser);
  const promotionActiveForNodes = useSelector(getAnyPromotionActiveForNodes);
  const [shouldShowPromotionModal, setShouldShowPromotionModal] = useState(true);
  const [shouldSeeModalCheckboxValue, setShouldSeeModalCheckboxValue] = useState(false);
  const auth = useSelector(getAuth);
  const isLoadingAppData = useSelector(isLoadingAppDataSelector);
  const { signed } = auth;
  const routeLocation = window.location.pathname;
  const isAtHomeRoute = routeLocation === '/home';
  const dispatch = useDispatch();

  const redirectToMoreInfoPage = () => {
    if (signed) {
      history.push('/');
      history.push('nodes');
    } else {
      history.push('home');
    }
    togglePromotionModal();
  };

  const getPromotionActive = () => {
    if (!promotionActiveForNodes) {
      return { promotionNodesCode: '', isFreePrice: undefined, isHalfPrice: undefined };
    }

    const {
      promotion_price_code: promotionNodesCode,
      is_free_price: isFreePrice,
      is_half_price: isHalfPrice,
    } = promotionActiveForNodes;

    return { promotionNodesCode, isFreePrice, isHalfPrice };
  };

  const shouldRenderPromotionModal = () => {
    if (signed) {
      const shouldRenderIfSigned =
        shouldShowPromotionModalForSignedUser && !isAtHomeRoute && shouldShowPromotionModal && !isLoadingAppData;
      return shouldRenderIfSigned;
    }

    const shouldRenderIfNotSigned =
      shouldShowPromotionModalForNotSignedUser && !isAtHomeRoute && shouldShowPromotionModal && !isLoadingAppData;

    return shouldRenderIfNotSigned;
  };

  const PromotionModalBody = () => {
    const { isFreePrice, isHalfPrice } = getPromotionActive();
    const promotions = {
      free: {
        title: 'FREE NODES',
        name: `🚀 Higlan's Free Nodes Promotion 🔥`,
        description: `For a limited time, we're offering the first month for free on as many nodes as you need.`,
      },
      half: {
        title: 'HALF PRICE NODES',
        name: `🚀 Higlan's Half Price Nodes Promotion! 🔥`,
        description: `For a limited time, you can deploy your nodes at HALF the price! 🎉 Don't miss this chance to scale your setup at 50% off.`,
      },
    };

    const selectedPromotion = isFreePrice
      ? promotions.free
      : isHalfPrice
      ? promotions.half
      : { title: '', name: '', description: '' };

    const { title: promotionTitle, name: promotionName, description: promotionDescription } = selectedPromotion;
    return (
      <PromotionModalBodyContainer>
        <ItemContainer>
          <PiConfettiFill size={PROMOTION_ICON_SIZE} />
          <TextRender>Limited Time Offer:</TextRender>
          <TextRender isBold>{promotionTitle}</TextRender>
        </ItemContainer>
        <ItemContainer>
          <TextRenderLine>
            Take the opportunity to enjoy
            <br /> <TextRenderLine isBold>{promotionName}</TextRenderLine>
          </TextRenderLine>
        </ItemContainer>
        <ItemContainer>
          <TextRenderLine isBold>{promotionDescription}</TextRenderLine>
        </ItemContainer>
        <ItemContainer>
          <TextRender>⚡ Act now—this exclusive deal won't last forever! ⚡</TextRender>
        </ItemContainer>
        <ItemContainer>
          <MoreInfoButton onClick={redirectToMoreInfoPage}>MORE INFO</MoreInfoButton>
        </ItemContainer>
      </PromotionModalBodyContainer>
    );
  };

  const handleCheckBoxClick = () => {
    setShouldSeeModalCheckboxValue(!shouldSeeModalCheckboxValue);
  };

  const PromotionModalFooter = () => {
    if (!signed) {
      return null;
    }
    return (
      <ModalFooterContainer>
        <Checkbox label={`Don't see it again`} checked={shouldSeeModalCheckboxValue} onClick={handleCheckBoxClick} />
      </ModalFooterContainer>
    );
  };

  if (!signed && isPrivate) {
    return <Redirect to="/login" />;
  }

  if (signed && !isPrivate && !isGeneric) {
    return <Redirect to="/dashboard" />;
  }

  let Layout = signed && !isGeneric ? DefaultLayout : AuthLayout;

  if (isLoadingAppData) {
    Layout = AuthLayout;
  }

  const togglePromotionModal = () => {
    const { promotionNodesCode } = getPromotionActive();
    setShouldShowPromotionModal(!shouldShowPromotionModal);
    if (signed) {
      dispatch(
        dismissPromotionModal({
          shouldSeePromotionModal: !shouldSeeModalCheckboxValue,
          promotionPriceCode: promotionNodesCode,
        })
      );
    }
  };

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
          {shouldRenderPromotionModal() && (
            <StyledModal
              bodyContent={<PromotionModalBody />}
              footerContent={<PromotionModalFooter />}
              title={`NEW PROMOTION`}
              show
              onHide={togglePromotionModal}
              hasConfirmation={false}
              submitButtonIcon={<PromotionIcon size={PROMOTION_ICON_SIZE} />}
            />
          )}
        </Layout>
      )}
    />
  );
}
