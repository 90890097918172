import { useState } from 'react';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { FaArrowLeft, FaCartArrowDown } from 'react-icons/fa';
import { FiMinusCircle } from 'react-icons/fi';
import uuid from 'react-uuid';

import {
  CreateOrderForm,
  PriceItemsContainer,
  InputContainer,
  NodeNumberInput,
  TotalOrderContainer,
  Footer,
  NodeNumberButton,
  InputControlsContainer,
  WalletAddressContainer,
  StyledTextInput,
  WalletAddressTextAlert,
  DiscountContainer,
  DiscountInput,
  DiscountButton,
  CreateOrderContainer,
  RuleTextContainer,
  RuleText,
  RulesModalTextContainer,
  StyledIoIcon,
  PriceValueContainer,
} from './styles';

import { ItemStrong, ItemNormal, BootstrapButton } from '../styles';
import MonthAmounts, { IMonthData } from './shared/MonthAmounts';
import { MAX_NUMBER_OF_NODES, MIN_NUMBER_OF_NODES } from '..';
import { displayTotalPrice } from '~/components/ui/NodeCard';
import { TextRender } from '~/components/ui/TextRender';
import { Modal } from '~/components/ui/Modal';
import { Tooltip } from '~/components/ui/Tooltip';
import { displayPriceWithCurrency, formatDecimal } from 'shared/string-utils';

interface ICreateOrder {
  coinNodePrice: number;
  coinName: string;
  handleSubmit: (event: React.FormEvent) => void;
  handleNumberOfNodes: (value: 'minus' | 'plus' | React.ChangeEvent<HTMLInputElement>) => void;
  handleWalletInputChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeDiscountCode: (value: React.ChangeEvent<HTMLInputElement>) => void;
  handleBuildingNode: () => void;
  handleMonthAmount: (data: IMonthData) => void;
  submitDiscountCode: (event: React.FormEvent) => void;
  removeDiscountCode: () => void;
  numberOfMonths: number;
  numberOfNodes: number;
  discountValue?: number;
  discountCode: string;
  totalOrder: number;
  isBuildingNode: boolean;
  shouldUseWalletAddress: boolean;
  walletInputValue: string;
  isValidWalletAddress: boolean;
  discountCodeLoading: boolean;
  is_free_price: boolean;
  is_half_price: boolean;
}

const FREE_NODES_RULES = [
  () => (
    <>
      Your <strong>account email</strong> should be <strong>verified</strong>.
    </>
  ),
  () => (
    <>
      You can only deploy <strong>1 node</strong> for free if you have <strong>no enabled nodes</strong> in the coin.
    </>
  ),
  () => (
    <>
      After having at least <strong>1 enabled node</strong> in the coin, the amount of nodes you can deploy for free is{' '}
      <strong>equal to the amount of your enabled nodes</strong> as long as you've <strong>enabled all nodes</strong>.
    </>
  ),
];

export function FreeNodeRules() {
  return (
    <RuleTextContainer>
      {FREE_NODES_RULES.map((freeNodeText, index) => (
        <RuleText key={uuid()}>
          {`${index + 1}. `}
          {typeof freeNodeText === 'function' ? freeNodeText() : `${freeNodeText}`}
        </RuleText>
      ))}
    </RuleTextContainer>
  );
}

export function CreateOrder({
  coinNodePrice,
  coinName,
  handleSubmit,
  handleNumberOfNodes,
  handleWalletInputChange,
  handleBuildingNode,
  handleMonthAmount,
  numberOfMonths,
  numberOfNodes,
  totalOrder,
  isBuildingNode,
  shouldUseWalletAddress,
  walletInputValue,
  isValidWalletAddress,
  submitDiscountCode,
  removeDiscountCode,
  onChangeDiscountCode,
  discountCodeLoading,
  discountValue,
  discountCode,
  is_free_price,
  is_half_price,
}: ICreateOrder) {
  const [shouldOpenRulesModal, setShouldOpenRulesModal] = useState(false);
  const halfPrice = Number(formatDecimal(Number(coinNodePrice) / 2, 2));
  const hasPromotion = is_free_price || is_half_price;

  const isDiscountCodeApplied = !!discountValue;
  const walletErrorMessage =
    walletInputValue === '' ? 'This field is required.' : `Invalid ${coinName} wallet address.`;

  const renderTotalOrder = () => {
    if (hasPromotion) {
      const oldPrice = coinNodePrice * numberOfNodes * numberOfMonths;
      return (
        <>
          {displayTotalPrice({
            oldPrice,
            newPrice: totalOrder,
            hasPromotion,
          })}
        </>
      );
    }

    if (!discountValue) {
      return <ItemNormal>{displayPriceWithCurrency(totalOrder)}</ItemNormal>;
    }

    const newTotalOrder = totalOrder - discountValue;

    return <>{displayTotalPrice({ oldPrice: totalOrder, newPrice: newTotalOrder, hasPromotion })}</>;
  };

  const toggleRulesModal = () => {
    setShouldOpenRulesModal(!shouldOpenRulesModal);
  };

  const OpenRulesModalComponent = () => {
    return (
      <RulesModalTextContainer>
        <TextRender>Read the rules.</TextRender>
      </RulesModalTextContainer>
    );
  };

  return (
    <CreateOrderContainer>
      <CreateOrderForm>
        <PriceItemsContainer>
          {is_free_price ? <ItemStrong>Price</ItemStrong> : <ItemStrong>Price per month</ItemStrong>}
          <PriceValueContainer>
            {hasPromotion ? (
              <>
                {displayTotalPrice({
                  hasMonthLabel: is_free_price,
                  oldPrice: coinNodePrice,
                  newPrice: is_free_price ? 0 : is_half_price ? halfPrice : 0,
                  hasPromotion,
                })}
                {is_free_price && (
                  <Tooltip content={<OpenRulesModalComponent />} forcedWidth={200}>
                    <StyledIoIcon size={20} onClick={toggleRulesModal} />
                  </Tooltip>
                )}
              </>
            ) : (
              <>{displayPriceWithCurrency(coinNodePrice)}</>
            )}
          </PriceValueContainer>
        </PriceItemsContainer>

        <InputContainer>
          <ItemStrong>Nodes</ItemStrong>
          <InputControlsContainer>
            <NodeNumberButton
              type="button"
              disabled={numberOfNodes === MIN_NUMBER_OF_NODES}
              onClick={() => handleNumberOfNodes('minus')}
            >
              <FiMinusCircle size={20} />
            </NodeNumberButton>
            <NodeNumberInput
              name="numberOfNodes"
              type="number"
              min="1"
              max="100"
              value={numberOfNodes}
              onChange={handleNumberOfNodes}
              required
            />
            <NodeNumberButton
              type="button"
              disabled={numberOfNodes === MAX_NUMBER_OF_NODES}
              onClick={() => handleNumberOfNodes('plus')}
            >
              <AiOutlinePlusCircle size={20} />
            </NodeNumberButton>
          </InputControlsContainer>
        </InputContainer>

        <MonthAmounts numberOfMonths={numberOfMonths} onSelectMonthAmount={handleMonthAmount} />

        <DiscountContainer>
          <DiscountInput
            placeholder="DISCOUNT CODE"
            value={discountCode}
            onChange={onChangeDiscountCode}
            disabled={isDiscountCodeApplied}
          />
          <DiscountButton
            variant={isDiscountCodeApplied ? 'danger' : 'success'}
            onClick={isDiscountCodeApplied ? removeDiscountCode : submitDiscountCode}
            disabled={discountCodeLoading}
          >
            {isDiscountCodeApplied ? 'REMOVE' : 'APPLY'}
          </DiscountButton>
        </DiscountContainer>

        <TotalOrderContainer>
          <ItemStrong>Total order</ItemStrong>
          {renderTotalOrder()}
        </TotalOrderContainer>

        {shouldUseWalletAddress && (
          <WalletAddressContainer>
            <ItemStrong>Your {coinName} wallet address</ItemStrong>
            <ItemStrong>(It has to be the primary address from your desktop wallet)</ItemStrong>
            <StyledTextInput
              value={walletInputValue}
              onChange={handleWalletInputChange}
              placeholder={
                'bxcooJHEfPs8fJqE1pDBRRP31eUa41ckfj8Us88Grm5qF7TULR9R9iK88N5Qe1GzhgioKU6461uGh7A7946ejUYt2kt8Bdaxx'
              }
            />
            {!isValidWalletAddress && <WalletAddressTextAlert>{walletErrorMessage}</WalletAddressTextAlert>}
          </WalletAddressContainer>
        )}
      </CreateOrderForm>
      {isBuildingNode && (
        <Footer>
          <BootstrapButton type="button" variant="primary" onClick={handleBuildingNode}>
            <FaArrowLeft /> BACK
          </BootstrapButton>

          <BootstrapButton variant="success" onClick={handleSubmit}>
            <FaCartArrowDown /> CHECK ORDER
          </BootstrapButton>
        </Footer>
      )}
      {shouldOpenRulesModal && (
        <Modal
          onHide={toggleRulesModal}
          show={true}
          title={`FREE NODES RULES`}
          hasConfirmation={false}
          bodyContent={<FreeNodeRules />}
        />
      )}
    </CreateOrderContainer>
  );
}
