import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import history from 'services/history';
import { toast } from 'react-toastify';

import { Container } from 'pages/_layouts/default/styles';

import api from 'services/api';
import { ISupportedCoinsNames } from 'store/modules/interfaces';

import { Modal } from 'components/ui/Modal';
import { getSupportedCoins } from 'store/modules/app/selectors';
import { CoinLogo } from 'components/CoinLogo';
import { Instructions } from 'components/ui/Instructions';
import { COIN_INFO } from './constants';

export const MAX_NUMBER_OF_NODES = 50;
export const MIN_NUMBER_OF_NODES = 1;

import {
  Header,
  CoinNameText,
  LoadingContainer,
  ItemStrong,
  ItemNormal,
  Footer,
  LinkButton,
  BootstrapButton,
  ModalBody,
  StyledTextInput,
  ModalWalletAddressContainer,
  MainContainer,
  ModalFooterContainer,
  DivElement,
} from './styles';
import { CreateOrder } from './CreateOrder';
import { displayPriceWithCurrency, formatDecimal, reduceKey } from 'shared/string-utils';
import { getCoinChainDataSelector } from '~/store/modules/coin-chain-data/selectors';
import { IMonthData } from './CreateOrder/shared/MonthAmounts';
import { displayTotalPrice } from '~/components/ui/NodeCard';

interface IModalFooter {
  totalOrder: number;
  discountValue?: number;
  hasPromotion: boolean;
  coinNodePrice: number;
  numberOfNodes: number;
  numberOfMonths: number;
}

interface IModalContent {
  numberOfNodes: number;
  numberOfMonthsText: string;
  coinDisplayName: string;
  walletAddress: string;
}

const ModalFooter = ({
  totalOrder,
  discountValue,
  hasPromotion,
  coinNodePrice,
  numberOfNodes,
  numberOfMonths,
}: IModalFooter) => {
  const renderTotalPrice = () => {
    if (hasPromotion) {
      const oldPrice = coinNodePrice * numberOfNodes * numberOfMonths;
      return (
        <>
          {displayTotalPrice({
            oldPrice,
            newPrice: totalOrder,
            hasPromotion,
          })}
        </>
      );
    }

    if (!discountValue) {
      return <ItemStrong>{`Total: ${displayPriceWithCurrency(totalOrder)}`}</ItemStrong>;
    }

    const newTotalOrder = totalOrder - discountValue;

    return (
      <>
        <ItemStrong>{`Total:`}</ItemStrong>
        {displayTotalPrice({ oldPrice: totalOrder, newPrice: newTotalOrder, hasPromotion })}
      </>
    );
  };
  return <ModalFooterContainer>{renderTotalPrice()}</ModalFooterContainer>;
};

const ModalContent = ({ numberOfNodes, coinDisplayName, walletAddress, numberOfMonthsText }: IModalContent) => {
  return (
    <ModalBody>
      <ItemNormal>You are about to build:</ItemNormal>
      <ItemStrong>{`${numberOfNodes} ${coinDisplayName} node(s)`}</ItemStrong>
      <ItemStrong>{`${numberOfMonthsText}`}</ItemStrong>
      {walletAddress && (
        <ModalWalletAddressContainer>
          <ItemStrong>{`Your ${coinDisplayName} wallet address`}</ItemStrong>
          <StyledTextInput value={reduceKey(walletAddress)} disabled />
        </ModalWalletAddressContainer>
      )}
    </ModalBody>
  );
};

export function NodeSteps() {
  const { coinName: coinNameParam } = useParams<{ coinName: string }>();
  // @ts-ignore
  const coinName: ISupportedCoinsNames = coinNameParam.toLowerCase();
  const supportedCoins = useSelector(getSupportedCoins);
  const selectedCoin = supportedCoins.find((item) => item.coin === coinName && item.is_active);
  const { is_free_price, is_half_price } = selectedCoin!;
  const coinNodePrice = Number(selectedCoin!.price);
  const display_name = selectedCoin!.display_name;
  const coinChainData = useSelector(getCoinChainDataSelector(coinName));
  const [isBuildingNode, setIsBuildingNode] = useState(false);
  const [isSendingApiData, setIsSendingApiData] = useState(false);
  const [numberOfNodes, setNumberOfNodes] = useState(1);
  const [numberOfMonths, setNumberOfMonths] = useState(1);
  const [numberOfMonthsText, setNumberOfMonthsText] = useState('1 month');
  const [totalOrder, setTotalOrder] = useState(coinNodePrice * numberOfNodes);
  const [modalShow, setModalShow] = useState(false);
  const [walletInputValue, setWalletInputValue] = useState('');
  const [discountCode, setDiscountCode] = useState('');
  const [discountCodeInputValue, setDiscountCodeInputValue] = useState('');
  const [discountCodeLoading, setDiscountCodeLoading] = useState(false);
  const [discountValue, setDiscountValue] = useState();
  const [isValidWalletAddress, setIsValidWalletAddress] = useState(false);
  const coinInstructions = COIN_INFO[coinName].instructions;
  const shouldUseWalletAddress = coinName === 'bdx';
  const walletRegex = selectedCoin?.wallet_address_regex;
  const hasPromotion = is_free_price || is_half_price;
  const halfPrice = Number(formatDecimal(Number(coinNodePrice) / 2, 2));

  useEffect(() => {
    let total = (is_half_price ? halfPrice : coinNodePrice) * numberOfNodes * numberOfMonths;
    if (is_free_price) {
      total -= coinNodePrice * numberOfNodes;
    }
    setTotalOrder(total);
  }, [numberOfNodes, numberOfMonths]);

  useEffect(() => {
    if (!shouldUseWalletAddress || !walletRegex) return;
    const walletRegexObj = eval(walletRegex);
    if (walletInputValue.match(walletRegexObj)) {
      setIsValidWalletAddress(true);
    } else {
      setIsValidWalletAddress(false);
    }
  }, [walletInputValue]);

  const getNumberOfNodes = (value: number) => {
    if (value <= 0) {
      toast.warning(`${MIN_NUMBER_OF_NODES} node is the min amount per order!`);
      return MIN_NUMBER_OF_NODES;
    }

    if (value > MAX_NUMBER_OF_NODES) {
      toast.warning(`${MAX_NUMBER_OF_NODES} nodes is the max amount per order!`);
      return MAX_NUMBER_OF_NODES;
    }

    return value;
  };

  const handleNumberOfNodes = (value: 'minus' | 'plus' | React.ChangeEvent<HTMLInputElement>) => {
    switch (value) {
      case 'minus': {
        const valueToSet = getNumberOfNodes(numberOfNodes);
        setNumberOfNodes(valueToSet - 1);
        break;
      }
      case 'plus': {
        const valueToSet = getNumberOfNodes(numberOfNodes);
        setNumberOfNodes(valueToSet + 1);
        break;
      }
      default: {
        const inputValue = Number(value.target.value);
        const valueToSet = getNumberOfNodes(inputValue);
        setNumberOfNodes(valueToSet);
      }
    }
  };

  const handleMonthAmount = ({ monthAmountNumber, monthAmountText }: IMonthData) => {
    setNumberOfMonths(monthAmountNumber);
    setNumberOfMonthsText(monthAmountText);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (shouldUseWalletAddress) {
      if (!isValidWalletAddress) {
        toast.error(`Invalid ${display_name} wallet address.`);
        return;
      }
    }
    setModalShow(true);
  };

  const handleBuildingNode = () => {
    setIsBuildingNode(!isBuildingNode);
  };

  const handleHideModal = () => {
    setModalShow(false);
  };

  const handleModalSubmit = async () => {
    setIsSendingApiData(true);
    const body = {
      amountGenkeys: numberOfNodes,
      coin: coinName,
      numberOfMonths,
      ...(shouldUseWalletAddress && {
        walletAddress: walletInputValue,
      }),
      ...(discountCode && {
        discountCode,
      }),
    };
    try {
      await api.post('masternodes', body);

      toast.success('Order processed successfully!');

      history.push('/dashboard');
    } catch (err) {
      const response: any = { err };

      const { data } = response.err.response;

      const { error } = data;

      toast.error(error);
    } finally {
      setIsSendingApiData(false);
    }
  };

  const onChangeDiscountCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = String(event.target.value).trim().toLowerCase();
    setDiscountCodeInputValue(value);
  };

  const submitDiscountCode = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!discountCodeInputValue) {
      toast.error(`Please insert a discount code.`);
      return;
    }
    try {
      setDiscountCodeLoading(true);
      const { data } = await api.post(`nodes/discount`, {
        discountCode: discountCodeInputValue,
        coin: coinName,
        totalOrder,
      });
      setDiscountValue(data.discountValue);
      setDiscountCode(discountCodeInputValue);
      const discountValueWithCurrency = displayPriceWithCurrency(data.discountValue);
      toast.success(
        <>
          Discount code successfully applied! You got <ItemStrong>{discountValueWithCurrency}</ItemStrong> discount for
          this order.
        </>,
        { autoClose: 8000 }
      );
    } catch (err) {
      const response: any = { err };

      const { data } = response.err.response;

      const { error } = data;

      toast.error(<DivElement dangerouslySetInnerHTML={{ __html: error }} />, { autoClose: 10000 });
    } finally {
      setDiscountCodeLoading(false);
    }

    return;
  };

  const removeDiscountCode = () => {
    setDiscountCode('');
    setDiscountValue(undefined);
    setDiscountCodeInputValue('');
  };

  const handleWalletInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = String(event.target.value).trim();
    setWalletInputValue(value);
  };

  return (
    <Container>
      <Header shouldUseSeparatorBorder={isBuildingNode}>
        <CoinNameText>{display_name}</CoinNameText>
        <CoinLogo coin={coinName} />
        <LoadingContainer>
          {coinChainData && (
            <>
              <ItemStrong>Blocks: </ItemStrong>
              <ItemNormal>{coinChainData.latestBlock}</ItemNormal>
              <ItemNormal> | </ItemNormal>
              <ItemStrong>Nodes: </ItemStrong>
              <ItemNormal>{coinChainData.totalNodes}</ItemNormal>
            </>
          )}
        </LoadingContainer>
      </Header>
      <MainContainer>
        {!isBuildingNode ? (
          <Instructions instructions={coinInstructions} />
        ) : (
          <CreateOrder
            handleSubmit={handleSubmit}
            handleNumberOfNodes={handleNumberOfNodes}
            coinNodePrice={coinNodePrice}
            coinName={display_name}
            handleBuildingNode={handleBuildingNode}
            handleMonthAmount={handleMonthAmount}
            numberOfMonths={numberOfMonths}
            isBuildingNode={isBuildingNode}
            totalOrder={totalOrder}
            numberOfNodes={numberOfNodes}
            shouldUseWalletAddress={shouldUseWalletAddress}
            handleWalletInputChange={handleWalletInputChange}
            walletInputValue={walletInputValue}
            isValidWalletAddress={isValidWalletAddress}
            submitDiscountCode={submitDiscountCode}
            removeDiscountCode={removeDiscountCode}
            onChangeDiscountCode={onChangeDiscountCode}
            discountCodeLoading={discountCodeLoading}
            discountValue={discountValue}
            discountCode={discountCodeInputValue}
            is_free_price={is_free_price}
            is_half_price={is_half_price}
          />
        )}

        {!isBuildingNode && (
          <Footer>
            <LinkButton to="/nodes">
              <BootstrapButton>
                <FaArrowLeft />
                BACK
              </BootstrapButton>
            </LinkButton>

            <BootstrapButton variant="primary" onClick={() => setIsBuildingNode(!isBuildingNode)}>
              NEXT <FaArrowRight />
            </BootstrapButton>
          </Footer>
        )}
      </MainContainer>
      {modalShow && (
        <Modal
          bodyContent={
            <ModalContent
              walletAddress={walletInputValue}
              numberOfNodes={numberOfNodes}
              numberOfMonthsText={numberOfMonthsText}
              coinDisplayName={display_name}
            />
          }
          footerContent={
            <ModalFooter
              totalOrder={totalOrder}
              coinNodePrice={coinNodePrice}
              numberOfNodes={numberOfNodes}
              discountValue={discountValue}
              hasPromotion={hasPromotion}
              numberOfMonths={numberOfMonths}
            />
          }
          handleModalSubmit={handleModalSubmit}
          title="CONFIRM YOUR ORDER"
          show={true}
          size="sm"
          onHide={handleHideModal}
          isSubmitting={isSendingApiData}
        />
      )}
    </Container>
  );
}
