import { AiFillGithub } from 'react-icons/ai';
import { FiMonitor } from 'react-icons/fi';
import createDisplay from 'number-display';

import {
  Container,
  CoinName,
  CoinInfoContainer,
  ItemContainer,
  ItemLabel,
  ItemDescription,
  Header,
  Footer,
  IconLink,
  FrozenWrapper,
  FrozenText,
  IconWrapper,
  TextWithLine,
  PriceContainer,
  NormalText,
  PromotionText,
  MonthLabelContainer,
  FreeNodeContainer,
} from './styles';
import { CoinLogo } from 'components/CoinLogo';
import { LabelNew } from '~/components/LabelNew';
import { displayPriceWithCurrency, formatDecimal } from 'shared/string-utils';
import { ISupportedCoins } from '~/store/modules/interfaces';
import { Tooltip } from '../Tooltip';

interface INodeCard {
  className?: string;
  onClick?: (value: any) => void;
  supportedCoin: ISupportedCoins;
  shouldUseWhiteHover?: boolean;
}

interface IDisplayTotalPrice {
  hasMonthLabel?: boolean;
  oldPrice: number;
  newPrice: number;
  hasPromotion: boolean;
}

export const displayTotalPrice = ({ hasMonthLabel = false, oldPrice, newPrice, hasPromotion }: IDisplayTotalPrice) => {
  if (hasMonthLabel && hasPromotion) {
    return (
      <PriceContainer>
        <TextWithLine>{displayPriceWithCurrency(oldPrice)}</TextWithLine>
        <NormalText>/</NormalText>
        <NormalText isGreen>{`${displayPriceWithCurrency(newPrice)}`}</NormalText>
        {hasMonthLabel && (
          <MonthLabelContainer>
            <NormalText isGreen>{`(First Month)`}</NormalText>
          </MonthLabelContainer>
        )}
      </PriceContainer>
    );
  }

  if ((!hasMonthLabel && hasPromotion) || oldPrice) {
    return (
      <PriceContainer>
        <TextWithLine>{displayPriceWithCurrency(oldPrice)}</TextWithLine>
        <NormalText>/</NormalText>
        <NormalText isGreen>{`${displayPriceWithCurrency(newPrice)}`}</NormalText>
      </PriceContainer>
    );
  }

  return <NormalText>{displayPriceWithCurrency(newPrice)}</NormalText>;
};

export function NodeCard({
  className = 'masternode-card',
  supportedCoin,
  onClick,
  shouldUseWhiteHover = false,
}: INodeCard) {
  const {
    coin,
    collateral,
    display_name,
    github_url,
    is_active,
    is_new,
    website_url,
    price,
    is_free_price,
    is_half_price,
  } = supportedCoin;

  const displayNumbers = createDisplay({
    length: 4,
    decimal: 0,
  });

  const onIconClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  const hasPromotion = is_free_price || is_half_price;

  const promotionText = is_free_price ? 'FREE NODES IN THE FIRST MONTH!' : 'HALF-PRICE NODES!';

  const halfPrice = Number(formatDecimal(Number(price) / 2, 2));

  return (
    <Container onClick={onClick} className={className} isFrozen={!is_active} shouldUseWhiteHover={shouldUseWhiteHover}>
      {!is_active && (
        <FrozenWrapper>
          <FrozenText>
            {'Frozen due to'}
            <br />
            {'Blockchain issues'}
          </FrozenText>
        </FrozenWrapper>
      )}
      {is_new && <LabelNew />}
      <Header>
        <CoinLogo coin={coin} />
        <CoinName>{display_name}</CoinName>
      </Header>
      <FreeNodeContainer isVisible={hasPromotion}>
        <PromotionText>{promotionText}</PromotionText>
      </FreeNodeContainer>
      <CoinInfoContainer>
        <ItemContainer>
          <ItemLabel>Collateral</ItemLabel>
          <ItemDescription>{displayNumbers(collateral)}</ItemDescription>
        </ItemContainer>
        <ItemContainer>
          <ItemLabel>Price</ItemLabel>

          {hasPromotion ? (
            <>
              {displayTotalPrice({
                oldPrice: Number(price),
                newPrice: is_free_price ? 0 : is_half_price ? halfPrice : Number(price),
                hasPromotion,
              })}
            </>
          ) : (
            <ItemDescription>{displayPriceWithCurrency(price)}</ItemDescription>
          )}
        </ItemContainer>
      </CoinInfoContainer>
      <Footer>
        <IconWrapper>
          <Tooltip content="Github">
            <IconLink onClick={onIconClick} href={github_url} target="_blank">
              <AiFillGithub size={25} />
            </IconLink>
          </Tooltip>
        </IconWrapper>
        <IconWrapper>
          <Tooltip content="Website">
            <IconLink onClick={onIconClick} href={website_url} target="_blank">
              <FiMonitor size={25} />
            </IconLink>
          </Tooltip>
        </IconWrapper>
      </Footer>
    </Container>
  );
}
